@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: Inter, sans-serif !important;
  scroll-behavior: smooth;
}
body {
  * {
    font-family: Inter !important;
    scroll-behavior: smooth;
  }
}

@import './Variables';

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/bootstrap-utilities.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/offcanvas';
@import '~bootstrap/scss/placeholders';

// Helpers
@import '~bootstrap/scss/helpers';

// Utilities
@import '~bootstrap/scss/utilities/api';

@import url(../../Assets/Style/_auth.scss);
@import url(../../Pages/Auth/Login/_login.scss);
@import url(../../Assets/Style/_react-datepicker.scss);



@import url(../../Components/Navbar/_navbar.scss);
@import url(../../Components/Sidebar/_sidebar.scss);
@import url(../../Layout/MainLayout/_mainLayout.scss);
// @import url(../../Components/Page-Title/_pageTitle.scss);
