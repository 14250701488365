.main_layout {
  background: rgba(209, 217, 224, 20%);
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: 60px 5px 1fr;
  grid-gap: 10px;
  min-height: 100vh;

  .sidebar_container {    
    grid-row: 2 / 4;
    position: fixed;
    top: 70px;
    height: calc(100% - 70px);
    width: 200px;
    background-color: white;
    border-radius: 0px 10px 0px 0px;
    box-shadow: 0px 10px 20px rgba(178, 191, 210, 0.25);
    transition: width 0.3s;
  }
  .sidebar_container_wrapped {
    width: 75px;
  }
  .herosection_container {
    display: flex;
    position: fixed;
    overflow: auto;
    top: 70px;
    left: 250px;
    height: calc(100% - 70px);
    width: calc(100% - 250px);
    grid-column: 2 / 4;
    flex-direction: column;
    transition: all 0.3s ease;
    &.active{
      left: 80px;
      width: calc(100% - 80px);
    }
    .breadCrumb {
      margin: 20px;
      margin-bottom: 0;
    }
    .children {
      height: auto;
      z-index: 50;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-button {
      opacity: 0;
    }
    &::-webkit-scrollbar-track {
      // background: rgb(124, 123, 123);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #6da5f9;
      border-radius: 10px;
      transition: 0.4s all ease-in-out !important;
      &:hover {
        background-color: #3e89fa;
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .main_layout {
    .sidebar_container {
      display: none;
      border: 3px solid red;
    }
    .herosection_container {
      left: 0;
      width: 100%;
    }
  }
}
