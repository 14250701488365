@import '../../Assets/Style/Variables';

// bootsrap class override 
.offcanvas-header {
  padding: 0.5rem 1rem !important;
}

.sidebarClose {
  float: right;
  margin-right: 10px;
  margin-bottom: 0.5rem;
}

.navbar_section {
  background-color: $white;
  z-index: 100;
  grid-column: 1 / 3;

  .hamburger {
    display: none;
  }

  .logo img {
    margin-left: 30px;
    height: 70px;
    width: 70px;

    &:hover {
      cursor: pointer;
    }
  }

  .user_actions {
    display: flex;
    justify-content: center;
    align-items: center;

    .user-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: $text-color;
    }

    .btn:focus {
      outline: none;
      box-shadow: none;
    }

    .dropdown {
      .dropdown-menu {
        margin: 0;
      }

      cursor: pointer;

      .down-arrow {
        transition: transform 0.5s ease;
      }

      &.show {
        .down-arrow {
          transform: rotate(180deg);
        }
      }
    }

    .profile_picture {
      margin: 10px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      overflow: hidden;

      img {
        height: 30px;
        width: 30px;
      }
    }
  }
}


@media screen and (max-width: 920px) {
  .navbar_section {
    .hamburger {
      display: block;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 575px) {
  .navbar_section .user_actions .notification .profile-btn .dropdown-menu {
    width: 320px;
  }
}