@import '../../../Assets/Style/Variables';

.login_option {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rememberMe {
    //   accent-color: $blue-500;
      display: flex;
      align-items: center;
      input {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        cursor: pointer;
      }
      label {
        cursor: pointer;
      }
    }
    .forgot_password {
      Link {
        color: $primary;
      }
    }
  }
  .errorResponse {
    color: red;
    margin-top: 10px;
  }

  .authForm_section {
    width: 100%;
    z-index: 100;
    position: relative;
    .authForm {
      width: 100%;
      min-width: 300px;
      max-width: 500px;
      margin: 20px 0;
      .authFormTitle {
        margin-bottom: 50px;
        align-items: center;
        img{
          height: auto;
          width: 175px;
        }
        h6 {
          text-align: center;
          width: fit-content;
          color: $base-color;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .authForm_section {
      width: 100%;
      margin: 20px;
      .authForm {
        width: 80%;
      }
    }
  }
  
  .auth_design {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  

  .right-container, .left-container {
    flex: 1;
    flex-shrink: 0;
    
    @media (max-width: 860px) {
      flex: 1 0 auto;
    }
  }
  
  .left-container {
    background-image: url(https://t4.ftcdn.net/jpg/02/86/06/27/360_F_286062753_FKcB2dm1YBtapP9WjyflxSGoEitpoJ5u.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 70%;
  }
  
  .right-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    overflow: auto;
    
    &__h2 {
      font-size: 30px;
    }
    
    &__p {
      opacity: .5;
      margin: 5px 0 10px;
    }
    
    .titleLogin{
      color: white;
      background-color: #369fff;
      padding: 10px;
      border-radius: 10px;
    }

    &__input {
      width: 350px;
      padding: 15px 20px;
      border-radius: 100px;
      border: 1px solid rgba(255, 255, 255, .5);
      background: transparent;
      color: #fff;
      
      &:focus {
        outline: none;
      } 
    }
    
    &__label {
      margin-top: 20px;
      font-size: 14px;
    }
    
    &__bottom-text {
      text-align: center;
      margin-top: 22px;
      font-size: 14px;
      color: rgba(255, 255, 255, .5);
      
      > strong {
        color: #fff;
      }
    }
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .toggle-container {
    margin: 24px 0;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .toggle-box {
    appearance: none;
    width: 32px;
    height: 16px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, .5);
    cursor: pointer;
    position: relative;
    
    &:checked {
      background-color: #30A2FF;
      
      &::after {
        transform: translateX(16px);
        transition: .4s ease-in-out;
      }
    }
    
    &::after {
      position: absolute;
      content: "";
      top: 3px;
      left: 3px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #fff;
    }
  }
  
  .btn {
    background-color: #30A2FF;
    border: 1px solid #30A2FF;
    width: 350px;
    border-radius: 20px;
    padding: 10px 0;
    color: #fff;
  }