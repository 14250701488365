@import '../../Assets/Style/Variables';

.sidebar {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-anchor: none;
  overflow-x: hidden;
  // background: #000;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  .nav_items {
    user-select: none;
    width: 80%;
    min-height: 42px;
    // height: 44px;
    text-align: left;
    text-decoration: none;  
    margin: 8px 0 0;
    display: flex;
    align-items: center;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    // color: #445774;
    padding: 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    transition: 0.1s all ease-in-out;
    border: 2px solid transparent;
    outline: 2px solid transparent;

    &:hover {
      border: 2px solid #1189f96f;
      background-color: #0486ff45;
    }

    &:active {
      transform: translateY(3px);
      background-color: #0486ff45;
    }
  }

  .nav_items_center {
    justify-content: center;
  }

  .active {
    background-color: $blue-500;
    color: $white;
    fill: $white;

    &:hover {
      background-color: $blue-500;
      color: $white;
      fill: $white;
    }

    &:active {
      background-color: $blue-500;
      transform: translateY(3px);
    }
  }

  .sidebar-icon {
    position: absolute;
    bottom: 20px;
    border: none;
    width: 25px;
    height: 25px;
    right: 0px;
    border-radius: 50%;
    background-color: #FFF;
    outline: none;
    box-shadow: 0px 10px 20px rgba(178, 191, 210, 0.25);
    transform: translateX(50%);

    @media (max-width:920px) {
      display: none;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
  display: none;
}